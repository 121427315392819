import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: "ui-full",
      disabled: _ctx.props.disabled
    }, {
      default: _withCtx(() => [
        ((_ctx.props.tipo === 1 || _ctx.props.tipo === 3))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 0,
              value: 1
            }, {
              default: _withCtx(() => [
                _createTextVNode("Acréscimo Valor")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ((_ctx.props.tipo === 1 || _ctx.props.tipo === 3))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 1,
              value: 2
            }, {
              default: _withCtx(() => [
                _createTextVNode("Acréscimo %")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ((_ctx.props.tipo === 1 || _ctx.props.tipo === 3))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 2,
              value: 3
            }, {
              default: _withCtx(() => [
                _createTextVNode("Valor Fixo")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ((_ctx.props.tipo === 1 || _ctx.props.tipo === 3))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 3,
              value: 4
            }, {
              default: _withCtx(() => [
                _createTextVNode("Desconto Valor")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ((_ctx.props.tipo === 1 || _ctx.props.tipo === 3))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 4,
              value: 5
            }, {
              default: _withCtx(() => [
                _createTextVNode("Desconto %")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ((_ctx.props.tipo === 2))
          ? (_openBlock(), _createBlock(_component_a_select_option, {
              key: 5,
              value: 6
            }, {
              default: _withCtx(() => [
                _createTextVNode("Prazo Adicional")
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["value", "onChange", "disabled"])
  ]))
}