
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IRegraFrete, IRegraFreteArea, IRegraFreteFormaEntrega } from '@/models/Entidades/IRegraFrete';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import EditorHtml from '@/core/components/UI/EditorHtml.vue';
import Icone from '@/core/components/UI/Icone.vue';
import ServicoRegraFrete from '@/servicos/ServicoRegraFrete';
import { IResposta } from '@/core/models/IResposta';
import { ETipoRegraFrete } from '@/models/Enumeradores/ETipoRegraFrete';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import CampoNumerico from '@/core/components/UI/CampoNumerico.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ComboAplicacaoRegraFrete from '@/components/RegrasFretes/ComboAplicacaoRegraFrete.vue';
import ComboFormaEntrega from '@/components/FormasEntregas/ComboFormaEntrega.vue';
import CaixaSelecao from '@/core/components/UI/CaixaSelecao.vue';
import { ICaixaSelecao } from '@/core/models/Tela/ICaixaSelecao';
import { EAplicacaoRegraFrete } from '@/models/Enumeradores/EAplicacaoRegraFrete';

export default defineComponent({
  name: 'RegraFreteCadastro',
  components: {
    ComunicacaoApi,
    EditorHtml,
    Icone,
    CampoCep,
    CampoNumerico,
    SelecionarData,
    ComboAplicacaoRegraFrete,
    ComboFormaEntrega,
    CaixaSelecao,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoRegraFrete = new ServicoRegraFrete();

    const state = reactive({
      regraFrete: {} as IRegraFrete,
      formasEntregasSelecionadas: [] as number[],
      opcoesCaixaSelacao: [] as ICaixaSelecao[],
    });

    function montaCaixaSelecao() {
      state.opcoesCaixaSelacao = [
        { valor: ETipoRegraFrete.Preco, Titulo: 'Preço', descricao: 'Aplicar acréscimos/descontos no frete para evitar possíveis prejuízos financeiros.' },
        { valor: ETipoRegraFrete.Prazo, Titulo: 'Prazo', descricao: 'Adicionar um prazo adicional no frete para suprir o tempo de envie/produção/reposição no estoque.' },
        { valor: ETipoRegraFrete.Impostos, Titulo: 'Impostos', descricao: 'Aplicar acréscimos/descontos no frete por causa de alguma questão tributária.' },
      ];
    }
    async function obterRegraFrete(codigo:number) {
      appBase.carregando = true;
      state.regraFrete = await servicoRegraFrete.obter(codigo);

      state.formasEntregasSelecionadas = [];
      if (state.regraFrete.formasEntrega.length > 0) {
        state.regraFrete.formasEntrega.forEach((formaEntrega) => {
          state.formasEntregasSelecionadas.push(formaEntrega.codigoFormaEntrega);
        });
      }
      appBase.carregando = false;
    }

    function novaAreaEntrega() {
      const regraFreteArea:IRegraFreteArea = {} as IRegraFreteArea;
      regraFreteArea.codigo = 0;
      regraFreteArea.codigoRegraFrete = 0;
      regraFreteArea.regiaoEstadoCidade = '';
      regraFreteArea.cepInicial = '';
      regraFreteArea.cepFinal = '';
      regraFreteArea.removida = false;
      regraFreteArea.alterada = false;
      state.regraFrete.areas.push(regraFreteArea);
    }

    function areaEntregaAlterada(index:number) {
      state.regraFrete.areas[index].alterada = true;
    }

    function removerAreaEntrega(index:number) {
      if (UtilitarioGeral.validaCodigo(state.regraFrete.areas[index].codigo)) {
        state.regraFrete.areas[index].removida = true;
      } else {
        state.regraFrete.areas.splice(index, 1);
      }
    }

    function limparDados() {
      state.formasEntregasSelecionadas = [];
      state.regraFrete = {} as IRegraFrete;
      state.regraFrete.codigo = 0;
      state.regraFrete.tipo = ETipoRegraFrete.Preco;
      state.regraFrete.status = true;
      state.regraFrete.areas = [];
      state.regraFrete.formasEntrega = [];
    }

    onBeforeMount(async () => {
      montaCaixaSelecao();
      limparDados();
      const codigoRegraFrete = obterCodigoRota();
      if (codigoRegraFrete > 0) {
        await obterRegraFrete(codigoRegraFrete);
      }
    });

    function preparaPersistenciaFormasEntregas() {
      const formasEntregas : IRegraFreteFormaEntrega[] = [];
      if (state.formasEntregasSelecionadas.length > 0) {
        state.formasEntregasSelecionadas.forEach((codigoFormaEntrega) => {
          const formaEntregaExistente = state.regraFrete.formasEntrega.find((c) => c.codigoFormaEntrega === codigoFormaEntrega);
          if (formaEntregaExistente !== undefined) {
            formasEntregas.push(formaEntregaExistente);
          } else {
            const formaEntrega: IRegraFreteFormaEntrega = {
              codigo: 0, codigoRegraFrete: state.regraFrete.codigo, codigoFormaEntrega,
            };
            formasEntregas.push(formaEntrega);
          }
        });
      }
      state.regraFrete.formasEntrega = formasEntregas;
    }

    async function salvar() {
      appBase.resposta = {} as IResposta;
      preparaPersistenciaFormasEntregas();
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações da Regra de Frete.');

      if (state.regraFrete.codigo === 0) {
        appBase.resposta = await servicoRegraFrete.incluir(state.regraFrete);
      } else {
        appBase.resposta = await servicoRegraFrete.atualizar(state.regraFrete);
      }

      apresentarRespostaRedirecionamento('RegrasFretes');
    }

    function verificarAlteracaoTipo(tipo:ETipoRegraFrete) {
      if (tipo === ETipoRegraFrete.Prazo) {
        state.regraFrete.aplicacao = EAplicacaoRegraFrete.PrazoAdicional;
      } else if (state.regraFrete.aplicacao === EAplicacaoRegraFrete.PrazoAdicional) {
        state.regraFrete.aplicacao = EAplicacaoRegraFrete.AcrescimoValor;
      }
    }
    return {
      appBase,
      state,
      salvar,
      ETipoRegraFrete,
      EAplicacaoRegraFrete,
      novaAreaEntrega,
      removerAreaEntrega,
      areaEntregaAlterada,
      verificarAlteracaoTipo,
    };
  },
});
